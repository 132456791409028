const config = {
  rootProjectId: "root",
  uiBucketName: "a101test.c.retter.io",
  appUrl: "https://api.a101test.retter.io/",
  cosUrl: "api.a101test.retter.io",
  version: "2.1.14",
  captchaKey: "6Lc5lZwpAAAAABEEJVj3E3kDOPs3g-tAYvb3cu7v",
  stage: "PROD"
}
export default config

