import React, { Component } from 'react'
import CustomSpinner from '../Components/CustomSpinner'
import { Button, Col, DatePicker, Form, Input, Row, Select, Skeleton, Space, Typography, notification } from 'antd'
import { Column, ILogsTableDetail } from '../Interfaces/ILogsTableDetail'
import { LogsTable } from '../Components/LogsTable'
import { MinusCircleOutlined } from '@ant-design/icons'
import queryString from 'query-string'
import _ from 'lodash'
import { IProjectContext, RootProjectContext } from '../Contexts/RootProjectContext'
import { RootProjectClassMethods } from '../Api/APIService'
import dayjs from 'dayjs'

const { Text } = Typography
const { Option } = Select
const { RangePicker }: any = DatePicker

interface Props {
    projectId: string
}

// type QueryLogItemOperator = '=' | '!=' | '>=' | '<=' | 'IN' | 'BEGINS_WITH' | 'ENDS_WITH' | 'CONTAIN'
const LogQueryOperators = ['EQ'] as const // 'IN', 'BETWEEN'
type LogQueryOperator = (typeof LogQueryOperators)[keyof typeof LogQueryOperators]

export interface IQueryLogFormDataItem {
    key: string
    operator: LogQueryOperator
    value: string | boolean | number | undefined
    type: string
}

export interface LogQueryInput {
    from: number
    to: number
    filters: Record<
        string,
        {
            operator: LogQueryOperator
            value: string | number | string[] | number[]
        }
    >
}

interface State {
    loading: boolean
    tableItems: any[]
    tableDetail?: ILogsTableDetail
    filterColumns: string[]
    filters: Column[]
    queryLogFormData: LogQueryInput
    autoSearch: boolean
    queryCurrentTime: boolean
}

class LogsLayout extends Component<Props, State> {
    ctx?: IProjectContext

    constructor(props: Props) {
        super(props)
        this.state = {
            loading: true,
            tableItems: [],
            filterColumns: [],
            filters: [],
            queryLogFormData: {
                from: Math.floor((Date.now() - 1000 * 60 * 5) / 1000),
                to: Math.floor(Date.now() / 1000),
                filters: {},
            },
            autoSearch: true,
            queryCurrentTime: true,
        }
        this.query = this.query.bind(this)
        this.addFilterSelectChanged = this.addFilterSelectChanged.bind(this)
        this.removeFilter = this.removeFilter.bind(this)
        this.filterFormOnFinish = this.filterFormOnFinish.bind(this)
        this.prepareFilters = this.prepareFilters.bind(this)
        this.updateFormFieldValue = this.updateFormFieldValue.bind(this)
        this.updateQueryLogFormData = this.updateQueryLogFormData.bind(this)
        this.operatorChangeHandler = this.operatorChangeHandler.bind(this)
        this.addFilterSelectChanged = this.addFilterSelectChanged.bind(this)
        this.filterFormOnFinish = this.filterFormOnFinish.bind(this)
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevState.queryLogFormData !== this.state.queryLogFormData) {
            window.history.pushState(
                {},
                '',
                window.location.pathname +
                    '?' +
                    queryString.stringify(
                        {
                            queryCurrentTime: this.state.queryCurrentTime,
                            autoSearch: this.state.autoSearch,
                            query: JSON.stringify(this.state.queryLogFormData),
                        },
                        { sort: false }
                    )
            )
        }
    }

    async prepareFilters() {
        // const filteredColumns: Column[] = []
        // let formData: LogQueryInput = {
        //     from: Math.floor((Date.now() - 1000 * 60 * 5) / 1000),
        //     to: Math.floor(Date.now() / 1000),
        //     filters: {},
        // }
        // let autoSearch = this.state.autoSearch
        let queryCurrentTime = this.state.queryCurrentTime
        if ((!window.location.search || window.location.search === '') && this.state.queryCurrentTime) {
            // formData = currentTimeFiltersData
        } else {
            const queryStings: any = queryString.parse(window.location.search, { arrayFormat: 'bracket', sort: false })

            try {
                const newQuery = JSON.parse(queryStings.query)
                this.setState({
                    queryLogFormData: newQuery,
                    autoSearch: queryStings.autoSearch === 'true',
                    queryCurrentTime: queryStings.queryCurrentTime === 'true',
                })
            } catch (error) {}

            // queryCurrentTime = queryStings.queryCurrentTime === 'true'
            // if (queryCurrentTime && (!queryStings.query || queryStings.query === '' || queryStings.query === '[]')) {
            //     formData = [...formData, ...currentTimeFiltersData]
            // }
            // if (queryStings.query) {
            //     try {
            //         let filters: IQueryLogFormDataItem[] = JSON.parse(queryStings.query)
            //         if (queryCurrentTime) {
            //             currentTimeFiltersData.forEach(f => {
            //                 formData.push(f)
            //             })
            //         }
            //         formData = [...formData, ...filters]
            //         formData = _.uniqBy(formData, 'key')
            //     } catch (e:any) {}
            // }
            // autoSearch = queryStings.autoSearch === 'true'
        }
        // formData.forEach((d, i) => {
        //     const column = this.state.filterColumns.find(c => c === d.key)
        //     if (column) {
        //         formData[i].type = column.Type!
        //         filteredColumns.push(column)
        //         switch (column.Type) {
        //             case 'number':
        //                 formData[i].value = parseInt(d.value as any)
        //                 break
        //             case 'boolean':
        //                 formData[i].value = d.value === 'true'
        //                 break
        //         }
        //     }
        // })

        this.setState({
            // filters: filteredColumns,
            // queryLogFormData: formData,
            // autoSearch,
            queryCurrentTime,
        })
    }

    async componentDidMount() {
        this.ctx = this.context
        if (!this.ctx) throw new Error('ctx not found')

        const response = await this.ctx.instance?.call<any>({
            method: RootProjectClassMethods.getLogsTableDetails,
        })
        if (!response || response.status >= 400) {
            throw new Error('getLogsTableDetails response error')
        }
        if (response) {
            this.setState({
                tableDetail: response.data,
                filterColumns: [...response.data.columns].filter(c => !['projectId'].includes(c)),
            })
        }
        await this.prepareFilters()
        if (this.state.autoSearch) {
            await this.query()
        }
        this.setState({ loading: false })
    }

    async query() {
        try {
            if (!this.ctx) throw new Error('ctx not found')
            this.setState({ loading: true })

            const response = await this.ctx.instance?.call<any>({
                method: RootProjectClassMethods.getLogs,
                body: this.state.queryLogFormData,
            })
            if (response) {
                const tableItems = []
                for (let i = 0; i < response.data.results.length; i++) {
                    tableItems.push({ ...response.data.results[i], ...{ key: i.toString() } })
                }
                this.setState({
                    tableItems,
                })
            }
        } catch (error: any) {
            notification.error({
                placement: 'bottomRight',
                message: error.response?.data ?? error.message,
            })
        } finally {
            this.setState({ loading: false })
        }
    }

    async filterFormOnFinish() {
        await this.query()
    }

    async addFilterSelectChanged(value: string[], option: any) {
        const newQuery = this.state.queryLogFormData
        const newFilters = option.map((o: any) => {
            const fieldData = newQuery.filters[o.data]
            if (!fieldData) {
                newQuery.filters[o.data] = {
                    operator: 'EQ',
                    value: '',
                }
            }
            return o.data
        })
        this.setState({
            filters: newFilters,
            queryLogFormData: this.state.queryLogFormData,
        })
    }

    async removeFilter(name: string) {
        // if (['year', 'month'].includes(name)) return false
        // const newFilters = this.state.filters.filter((o: any) => {
        //     return o.Name !== name
        // })
        // this.setState({
        //     filters: newFilters,
        //     queryLogFormData: this.state.queryLogFormData.filter(d => d.key !== name),
        // })
        const newQuery = this.state.queryLogFormData
        delete newQuery.filters[name]
        this.setState({
            queryLogFormData: newQuery,
        })
    }

    async updateFormFieldValue(key: string, value: string | undefined) {
        await this.updateQueryLogFormData(key, { value })
    }

    // getInitialFormData(key: string): { key: string; operator: QueryLogItemOperator; value: string | undefined | number | boolean } {
    //     const field = this.state.queryLogFormData.find(d => d.key === key)
    //     if (field) return field
    //     return {
    //         key: '',
    //         operator: '=',
    //         value: undefined,
    //     }
    // }

    async updateQueryLogFormData(key: string, props: { value?: string; operator?: LogQueryOperator }) {
        // const copy: IQueryLogFormDataItem[] = JSON.parse(JSON.stringify(this.state.queryLogFormData))
        // let index = -1
        // copy.forEach((d, i) => {
        //     if (d.key === key) index = i
        // })
        // if (props.value) copy[index].value = props.value
        // if (props.operator) copy[index].operator = props.operator
        // this.setState({
        //     queryLogFormData: copy,
        // })
        this.setState({
            queryLogFormData: {
                ...this.state.queryLogFormData,
                filters: {
                    ...this.state.queryLogFormData.filters,
                    [key]: {
                        ...this.state.queryLogFormData.filters[key],
                        ...props,
                    },
                },
            },
        })
    }

    async operatorChangeHandler(key: string, operator: LogQueryOperator) {
        await this.updateQueryLogFormData(key, { operator })
    }

    render() {
        return (
            <>
                <CustomSpinner spinning={this.state.loading} skeleton={false}>
                    <Form name="logs_query" onFinish={this.filterFormOnFinish} layout="vertical" id={'logs_query_form'} key={'logs_query_form'}>
                        <Form.Item>
                            <Row justify="space-between" gutter={[18, 6]}>
                                <Col span={8}>
                                    <RangePicker
                                        onChange={(e: any) => {
                                            if (e.filter(Boolean).length === 2) {
                                                this.setState({
                                                    queryLogFormData: {
                                                        ...this.state.queryLogFormData,
                                                        from: e[0].unix(),
                                                        to: e[1].unix(),
                                                    },
                                                })
                                            }
                                        }}
                                        style={{ width: '100%' }}
                                        value={[dayjs(this.state.queryLogFormData.from * 1000), dayjs(this.state.queryLogFormData.to * 1000)]}
                                        showTime
                                    />
                                </Col>
                                <Col span={10}>
                                    <Select
                                        onChange={this.addFilterSelectChanged}
                                        onDeselect={async (item) => await this.removeFilter(item)}
                                        mode="multiple"
                                        value={Object.keys(this.state.queryLogFormData.filters)}
                                        allowClear
                                        placeholder="Add Filter"
                                    >
                                        {this.state.tableDetail
                                            ? this.state.filterColumns.map(c => (
                                                  <Option value={c} data={c}>
                                                      {c}
                                                  </Option>
                                              ))
                                            : null}
                                    </Select>
                                </Col>
                                <Col span={6}>
                                    <Button type="primary" htmlType={'submit'} loading={this.state.loading} block>
                                        Get
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                        {Object.keys(this.state.queryLogFormData.filters).map(fr => {
                            const f = this.state.queryLogFormData.filters[fr]
                            return (
                                <Space key={fr} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                        key={[fr, 'key'].join('#')}
                                        initialValue={fr}
                                        style={{ width: '10vw' }}
                                        fieldKey={[fr, 'key']}
                                        name={[fr, 'key']}
                                        rules={[{ required: true, message: 'Missing key' }]}
                                    >
                                        <Text strong key={fr}>
                                            {fr}
                                        </Text>
                                    </Form.Item>
                                    <Form.Item
                                        initialValue={f.operator}
                                        fieldKey={[fr, 'operator']}
                                        key={[fr, 'operator'].join('#')}
                                        style={{ width: 150 }}
                                        name={[fr, 'operator']}
                                        rules={[{ required: true, message: 'Missing operator' }]}
                                    >
                                        <Select
                                            key={fr + '#' + 'select'}
                                            placeholder="Select operator"
                                            onChange={async (vt, o: any) => {
                                                await this.operatorChangeHandler(fr, o.value)
                                            }}
                                        >
                                            {LogQueryOperators.map(o => (
                                                <Option key={o} value={o}>
                                                    {o}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item initialValue={f.value} style={{ width: '17vw' }} name={[fr, 'value']} key={[fr, 'value'].join('#')} fieldKey={[fr, 'value']}>
                                        <Input
                                            key={fr + '#value#input'}
                                            value={f.value as string}
                                            onChange={async event => {
                                                await this.updateFormFieldValue(fr, event.target.value)
                                            }}
                                        />
                                    </Form.Item>
                                    <MinusCircleOutlined
                                        onClick={async () => {
                                            await this.removeFilter(fr)
                                        }}
                                    />
                                </Space>
                            )
                        })}
                    </Form>
                    {!this.state.loading && this.state.tableItems ? <LogsTable items={this.state.tableItems} projectId={this.props.projectId} /> : <Skeleton />}
                </CustomSpinner>
            </>
        )
    }
}

LogsLayout.contextType = RootProjectContext

export default LogsLayout
