import React, { Component } from 'react'
import { Affix, Button, Layout, Menu, Modal, notification, Spin, Tabs, Tag } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import {
    ExclamationCircleOutlined,
    FileSearchOutlined,
    FileTextOutlined,
    LinkOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    PartitionOutlined,
    SortAscendingOutlined,
    ThunderboltOutlined,
} from '@ant-design/icons'
import { DataNode } from 'rc-tree/lib/interface'
import { CosInitialPayloadTab } from './Components/CosInitialPayloadTab'
import { CosStatesTab } from './Components/CosStatesTab'
import { CosMethodTab } from './Components/CosMethodTab'
import RBS, { RetterCloudObject, RetterCloudObjectMethod } from '@retter/sdk'
import LogsLayout from '../LogsLayout'
import { CosLookupKeyList } from './Components/CosLookupKeyList'
import { CosSortedSetList } from './Components/CosSortedSetList'
import { ActionTypes, CosActionStoreTypes, cosInstanceStore, topMenuStore } from '../../Actions/Actions'
import { GlobalHelpers } from '../../GlobalHelpers'
import { IProjectContext } from '../../Contexts/RootProjectContext'
import { ThisProjectContext } from '../../Contexts/ThisProjectContext'
import _, { get } from 'lodash'
import { RetterCloudObjectConfig } from '@retter/sdk/dist/types'
import { RootProjectClassEnums, RootProjectClassMethods } from '../../Api/APIService'
import { withAntdToken } from '../../Components/with-antd-token-hoc'
import config from '../../config/autoGenConfig'
import axios from 'axios'
import { gunzipSync } from 'zlib'
import YAML from 'yaml'
import staticMethods from 'antd/es/message'

const { cosUrl } = config

const { Sider } = Layout

const operationTypeColors: { [key: string]: string } = {
    STATIC: 'blue',
    READ: 'green',
    WRITE: 'orange',
    QW: 'yellow',
    AS: 'purple', // async-static 
    AR: 'teal',    // async-read 
  };

const getMethodType = (type: string) => {
    switch (type) {
        case 'QUEUED_WRITE':
            return 'QW'
        case 'ASYNC_STATIC':
            return 'AS'
        case 'ASYNC_READ':
            return 'AR'
        default:
            return type
    }
}
export enum CustomTabs {
    State = 'Custom.State',
    InitialPayload = 'Custom.InitialPayload',
    Logs = 'Logs',
    LookupKeys = 'Custom.LookupKeys',
    SortedSets = 'Custom.SortedSets',
}

export interface COSTabPaneItem {
    closable?: boolean
    key: CustomTabs | string
    content: any
}

interface State {
    loading: boolean
    cosInstance?: RetterCloudObject
    panes: COSTabPaneItem[]
    treeData: DataNode[]
    initialPayload?: string
    tabActiveKey: string
    methodTabCounter: number
    inlineCollapsed: boolean
    thisClassInstance?: RetterCloudObject
    isStateEditable: boolean
}

interface Props {
    token: any
    rootProjectSdk: RBS
    routeComponentProps: RouteComponentProps<{ projectId: string; classId: string; instanceId?: string }>
}

class CosDebugLayout extends Component<Props, State> {
  projectId: string;
  classId: string;
  ctx?: IProjectContext;

  constructor(props: Props) {
    super(props);
    this.projectId = this.props.routeComponentProps.match.params.projectId;
    this.classId = this.props.routeComponentProps.match.params.classId;
    this.state = {
      methodTabCounter: 1,
      loading: true,
      panes: [],
      treeData: [],
      initialPayload: "{}",
      tabActiveKey: CustomTabs.InitialPayload,
      inlineCollapsed:
        localStorage.getItem("RBS_LEFT_MENU_COLLAPSED") !== "true",
      isStateEditable: false,
    };
    this.init = this.init.bind(this);
    this.onBack = this.onBack.bind(this);
    this.getInstanceByInstanceIdFormOnFinish =
      this.getInstanceByInstanceIdFormOnFinish.bind(this);
    this.onInitialPayloadChanged = this.onInitialPayloadChanged.bind(this);
    this.treeCustomItemDoubleClick = this.treeCustomItemDoubleClick.bind(this);
    this.TABonChange = this.TABonChange.bind(this);
    this.TABshowClosePaneConfirmation =
      this.TABshowClosePaneConfirmation.bind(this);
    this.TABonEdit = this.TABonEdit.bind(this);
    this.TABremovePane = this.TABremovePane.bind(this);
    this.TABgetTitle = this.TABgetTitle.bind(this);
    this.createMethodTab = this.createMethodTab.bind(this);
    this.preInit = this.preInit.bind(this);
    this.getInstanceByKeyValueFormOnFinish =
      this.getInstanceByKeyValueFormOnFinish.bind(this);
    this.createLogsTab = this.createLogsTab.bind(this);
    this.deleteInstance = this.deleteInstance.bind(this);
  }

  async componentDidMount() {
    const pathInstanceId =
      this.props.routeComponentProps.match.params.instanceId;

    if (pathInstanceId) {

      await this.preInit();
      await this.init({
        httpMethod: "post",
        instanceId: pathInstanceId,
      });
  
    } else {

      // create temporary local instance so we can call static methods
      const thisProjectSdk = GlobalHelpers.getRbsInstanceByProjectId(
        this.projectId
      );

      const thisClassInstance = await thisProjectSdk.getCloudObject({
        classId: this.classId,
        instanceId: "RIO_CONSOLE_STATIC_MODE", // we need to useLocal, but sdk cant do useLocal without instanceId, not sure why
        useLocal: true,
      });

      const static_methods = await this.getStaticMethods();

      thisClassInstance.methods = static_methods

      this.setState({
        thisClassInstance,
      });
      
      await this.preInit();

    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (prevState.cosInstance !== this.state.cosInstance) {
      this.prepareTopMenu();
    }
  }

  onInitialPayloadChanged(value: string | undefined) {
    this.setState({
      initialPayload: value,
    });
  }

  /*
    createInitialPayloadTab() {
        return {
            key: CustomTabs.InitialPayload,
            content: <CosInitialPayloadTab initialValue={this.state.initialPayload}
                                           onChange={this.onInitialPayloadChanged}/>
        }
    }
     */

  createMethodTab(key: string) {
    const preparedKey = key + "." + this.state.methodTabCounter;
    this.setState({
      methodTabCounter: this.state.methodTabCounter + 1,
    });
    const method = key.replace("Method.", "");
    return {
      key: preparedKey,
      content: (
        <CosMethodTab
          rootSdkInstance={this.props.rootProjectSdk}
          methodDetail={this.state.thisClassInstance?.methods.find(
            (m) => m.name === method
          )}
          thisClassInstance={this.state.thisClassInstance}
          projectId={this.projectId}
          input={{
            classId: this.classId,
            instanceId: this.state.cosInstance
              ? this.state.cosInstance.instanceId
              : undefined,
            method,
          }}
        />
      ),
    };
  }

  createStatesTab() {
    if (!this.state.cosInstance) throw new Error("Instance not found");
    return {
      key: CustomTabs.State,
      content: (
        <CosStatesTab
          rootSdkInstance={this.props.rootProjectSdk}
          thisClassInstance={this.state.thisClassInstance}
          projectId={this.projectId}
          input={{
            classId: this.classId,
            instanceId: this.state.cosInstance.instanceId,
          }}
          isStateEditable={this.state.isStateEditable}
        />
      ),
    };
  }

  createLogsTab() {
    if (!this.state.cosInstance) throw new Error("Instance not found");
    return {
      key: CustomTabs.Logs,
      content: <LogsLayout projectId={this.projectId} />,
    };
  }

  createLookupKeysTab() {
    if (!this.state.cosInstance) throw new Error("Instance not found");
    return {
      key: CustomTabs.LookupKeys,
      content: (
        <CosLookupKeyList
          rootRbsSdk={this.props.rootProjectSdk}
          projectId={this.projectId}
          classId={this.classId}
          instanceId={this.state.cosInstance.instanceId}
        />
      ),
    };
  }

  createSortedSetsTab() {
    if (!this.state.cosInstance) throw new Error("Instance not found");
    return {
      key: CustomTabs.SortedSets,
      content: (
        <CosSortedSetList
          rootRbsSdk={this.props.rootProjectSdk}
          projectId={this.projectId}
          classId={this.classId}
          instanceId={this.state.cosInstance.instanceId}
        />
      ),
    };
  }

  componentWillUnmount() {
    topMenuStore.dispatch({
      type: ActionTypes.TOP_MENU_CHANGED.types.CHANGED,
      data: {
        extraMenu: [],
      },
    });
  }

  async getStaticMethods(): Promise<RetterCloudObjectMethod[]> {
    try {
      const class_instance = await this.props.rootProjectSdk.getCloudObject({
        classId: "RetterClass",
        instanceId: `${this.projectId}_${this.classId}`,
        useLocal: true,
      });

      const methods: any = await class_instance.call({ method: "getFiles" });

      if (methods.status !== 200) return [];

      const template = methods.data.find((m: any) => m.name === "template.yml");

      if (!template) return [];

      const parsed_template = YAML.parse(
        gunzipSync(Buffer.from(template.content, "base64")).toString("utf-8")
      );

      if (!parsed_template.methods) return [];

      const static_methods = parsed_template.methods.filter((m: any) => m.type === 'STATIC' || m.type === 'ASYNC_STATIC')
      const named_static_methods = static_methods.map((item: any) => ({
          ...item,
          name: item.method,
      })) as RetterCloudObjectMethod[]

      return named_static_methods;
    } catch {
      return [];
    }
  }

  async deleteInstance() {
    const { projectId, classId, instanceId } =
      this.props.routeComponentProps.match.params;
    if (this.state.cosInstance && projectId && classId && instanceId) {
      this.setState({
        loading: true,
      });

      try {
        const currentUser = await this.props.rootProjectSdk.getCurrentUser();
        if (!currentUser) throw new Error("current user not found");

        const token = await GlobalHelpers.getAccessTokenForCustomUser(
          this.props.rootProjectSdk,
          projectId,
          {
            identity: currentUser.identity!,
            userId: currentUser.userId!,
          }
        );

        const url = `https://${cosUrl}/${projectId}/DESTROY/${classId}/${instanceId}`;
        await axios({
          method: "post",
          url,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        notification.success({
          placement: "bottomRight",
          message: "Success",
        });
        window.location.href = `/project/${projectId}/Classes/${classId}`;
      } catch (e: any) {
        if (e.response) {
          notification.error({
            placement: "bottomRight",
            message: e.response.data,
          });
        }
      }
      this.setState({
        loading: false,
      });
    }
  }

  async preInit() {
    this.setState({
      loading: false,
      methodTabCounter: 1,
      cosInstance: undefined,
      panes: [
        {
          closable: false,
          key: CustomTabs.InitialPayload,
          content: (
            <>
              <CosInitialPayloadTab
                deleteInstance={this.deleteInstance}
                initialValue={this.state.initialPayload}
                payloadOnChange={this.onInitialPayloadChanged}
                routeComponentProps={this.props.routeComponentProps}
                getInstanceByInstanceIdFormOnFinish={
                  this.getInstanceByInstanceIdFormOnFinish
                }
                getInstanceByKeyValueFormOnFinish={
                  this.getInstanceByKeyValueFormOnFinish
                }
              />
            </>
          ),
        },
      ],
      treeData: [
        {
          title: "Initial Payload",
          key: CustomTabs.InitialPayload,
          icon: <FileTextOutlined />,
        },
        {
          title: "State",
          key: CustomTabs.State,
          icon: <PartitionOutlined />,
        },
        {
          title: "Lookup Keys",
          key: CustomTabs.LookupKeys,
          icon: <FileSearchOutlined />,
        },
        {
          title: "Sorted Sets",
          key: CustomTabs.SortedSets,
          icon: <SortAscendingOutlined />,
        },
        {
          title: "Methods",
          key: "Methods",
          children: this.state.thisClassInstance?.methods.filter((m: any) => m.method).map((m: any) => {
            return {
              title: m.method,
              key: "Method." + m.method,
              icon: <ThunderboltOutlined />,
              type: getMethodType(m.type),
            };
          }),
        },
      ],
      tabActiveKey: CustomTabs.InitialPayload,
    });
  }

  async init(
    input?: {
      httpMethod: "get" | "post";
      _preventNotFound?: boolean;
      instanceId?: string;
      key?: { name: string; value: string };
    },
    payload?: any
  ) {
    if (!this.props.rootProjectSdk)
      throw new Error("this project instance is not defined");
    this.setState({
      loading: true,
    });
    cosInstanceStore.dispatch({
      type: CosActionStoreTypes.FETCHING,
      data: {},
    });
    let thisProjectSdk;

    let currentUser;
    try {
      currentUser = await this.props.rootProjectSdk.getCurrentUser();
      thisProjectSdk = GlobalHelpers.getRbsInstanceByProjectId(this.projectId);
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.message) {
        notification.error({
          placement: "bottomRight",
          message:
            e.response.data && e.response.data.issues
              ? e.response.data.issues.map((i: any) => i.message).join(", ")
              : e.response.data.message,
        });
      } else {
        notification.error({
          placement: "bottomRight",
          message: "Error",
        });
      }
      this.setState({ loading: false });
      return false;
    }
    if (!currentUser) throw new Error("Current user not found");

    let cosInstance;
    try {
      const token = await GlobalHelpers.getAccessTokenForCustomUser(
        this.props.rootProjectSdk,
        this.projectId,
        {
          identity: currentUser.identity!,
          userId: currentUser.userId!,
        }
      );
      const gco: RetterCloudObjectConfig = {
        httpMethod: input ? input.httpMethod : "post",
        classId: this.classId,
        instanceId: input ? input.instanceId : undefined,
        key: input ? input.key : undefined,
        token,
      };
      if (gco.httpMethod === "get") {
        if (typeof payload === "string") {
          try {
            gco.queryStringParams = JSON.parse(payload);
          } catch (e: any) {}
        } else if (typeof payload === "object") {
          gco.queryStringParams = payload;
        }
      } else {
        gco.body = payload;
      }
      cosInstance = await thisProjectSdk.getCloudObject(gco);
      this.setState({
        thisClassInstance: cosInstance,
      });
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.message) {
        notification.error({
          placement: "bottomRight",
          message:
            e.response.data && e.response.data.issues
              ? e.response.data.issues.map((i: any) => i.message).join(", ")
              : e.response.data.message,
        });
      } else {
        notification.error({
          placement: "bottomRight",
          message: "Error",
        });
      }
      this.setState({ loading: false });
      return false;
    }

    if (cosInstance) {
      this.props.routeComponentProps.history.replace(
        `/project/${this.projectId}/Classes/${this.classId}/instance/${cosInstance?.instanceId}`
      );
      await this.preInit();
      const methodTreeIndex = this.state.treeData.findIndex(
        (d) => d.key === "Methods"
      );

      if (methodTreeIndex > -1) {
        this.state.treeData[methodTreeIndex].children = cosInstance.methods
          .filter((m: any) => m.name !== "setState")
          .map((m: any) => {
            return {
              title: m.name,
              key: "Method." + m.name,
              icon: <ThunderboltOutlined />,
              type: getMethodType(m.type),
            };
          });
      }

      this.setState({
        treeData: this.state.treeData,
        cosInstance,
        isStateEditable:
          cosInstance.methods.findIndex((m: any) => m.name === "setState") > -1,
      });
    }
    this.setState({ loading: false });
  }

  onBack() {
    this.props.routeComponentProps.history.push(
      `/project/${this.projectId}/Classes/${this.classId}`
    );
  }

  async getInstanceByInstanceIdFormOnFinish(input: {
    instanceId?: string;
    httpMethod: "get" | "post";
  }) {
    if (!input) return false;
    await this.init(
      {
        httpMethod: input.httpMethod,
        instanceId:
          !input.instanceId || input.instanceId === ""
            ? undefined
            : input.instanceId,
      },
      this.state.initialPayload
    );
    return true;
  }

  async getInstanceByKeyValueFormOnFinish(keyValueInput?: {
    name: string;
    value: string;
    _preventNotFound: boolean;
    httpMethod: "get" | "post";
  }) {
    if (!keyValueInput) return false;
    if (
      !keyValueInput.value ||
      !keyValueInput.name ||
      keyValueInput.value === "" ||
      keyValueInput.name === ""
    ) {
      notification["error"]({
        placement: "bottomRight",
        message: "Key Value pair must be defined",
      });
      return false;
    }
    await this.init(
      {
        httpMethod: keyValueInput.httpMethod,
        _preventNotFound: keyValueInput._preventNotFound,
        key: keyValueInput,
      },
      this.state.initialPayload
    );
    return true;
  }

  treeCustomItemDoubleClick(key: string) {
    const alreadyExistIndex = this.state.panes.findIndex((p) => p.key === key);
    if (alreadyExistIndex > -1) {
      this.setState({
        tabActiveKey: key,
      });
    } else {
      if (key.startsWith("Method.")) {
        const createdTab = this.createMethodTab(key);
        this.setState({
          panes: [...this.state.panes, ...[createdTab]],
          tabActiveKey: createdTab.key,
        });
      } else if (key.startsWith("Custom.")) {
        switch (key as CustomTabs) {
          case CustomTabs.InitialPayload:
            /*
                        const createdTab = this.createInitialPayloadTab()
                        this.setState({
                            panes: [...this.state.panes, ...[createdTab]],
                            tabActiveKey: createdTab.key
                        })
                         */
            break;
          case CustomTabs.State:
            if (this.state.cosInstance) {
              const createdTab = this.createStatesTab();
              this.setState({
                panes: [...this.state.panes, ...[createdTab]],
                tabActiveKey: createdTab.key,
              });
            }
            break;
          case CustomTabs.Logs:
            if (this.state.cosInstance) {
              const createdTab = this.createLogsTab();
              this.setState({
                panes: [...this.state.panes, ...[createdTab]],
                tabActiveKey: createdTab.key,
              });
            }
            break;
          case CustomTabs.LookupKeys:
            if (this.state.cosInstance) {
              const createdTab = this.createLookupKeysTab();
              this.setState({
                panes: [...this.state.panes, ...[createdTab]],
                tabActiveKey: createdTab.key,
              });
            }
            break;
          case CustomTabs.SortedSets:
            if (this.state.cosInstance) {
              const createdTab = this.createSortedSetsTab();
              this.setState({
                panes: [...this.state.panes, ...[createdTab]],
                tabActiveKey: createdTab.key,
              });
            }
            break;
          default:
            return;
        }
      }
    }
  }

  TABonChange(tabActiveKey: string) {
    this.setState({
      tabActiveKey,
    });
  }

  async TABshowClosePaneConfirmation(tabKey: string) {
    return new Promise((resolve) => {
      Modal.confirm({
        title: "Do you want to close?",
        icon: <ExclamationCircleOutlined />,
        content: (
          <>
            Are you sure you want to close <b>{this.TABgetRawTitle(tabKey)}</b>?
          </>
        ),
        okText: "Close",
        okType: "danger",
        cancelText: "Cancel",
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  }

  async TABonEdit(
    e: React.MouseEvent | React.KeyboardEvent | string,
    action: "add" | "remove"
  ) {
    switch (action) {
      case "remove":
        await this.TABremovePane(e as string);
        break;
      default:
        return;
    }
  }

  async TABremovePane(targetKey: string) {
    if (!(await this.TABshowClosePaneConfirmation(targetKey))) return;
    let { tabActiveKey } = this.state;
    let lastIndex = -1;
    this.state.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const panes = this.state.panes.filter((pane) => pane.key !== targetKey);
    if (panes.length && tabActiveKey === targetKey) {
      if (lastIndex >= 0) {
        tabActiveKey = panes[lastIndex].key;
      } else {
        tabActiveKey = panes[0].key;
      }
    }
    this.setState({ panes, tabActiveKey });
  }

  TABgetRawTitle(key: string) {
    if (key.startsWith("Method.")) {
      return key.replace("Method.", "");
    } else {
      switch (key) {
        case CustomTabs.InitialPayload:
          return "Initial Payload";
        case CustomTabs.State:
          return "States";
        case CustomTabs.LookupKeys:
          return "Lookup Keys";
        case CustomTabs.SortedSets:
          return "Sorted Sets";
        default:
          return "";
      }
    }
  }

  TABgetTitle(key: CustomTabs | string) {
    const rawTitle = this.TABgetRawTitle(key);
    if (key.startsWith("Method.")) {
      return (
        <>
          <ThunderboltOutlined />
          {rawTitle}
        </>
      );
    }
    switch (key) {
      case CustomTabs.InitialPayload:
        return (
          <>
            <FileTextOutlined />
            {rawTitle}
          </>
        );
      case CustomTabs.State:
        return (
          <>
            <PartitionOutlined />
            {rawTitle}
          </>
        );
      case CustomTabs.LookupKeys:
        return (
          <>
            <FileSearchOutlined />
            {rawTitle}
          </>
        );
      case CustomTabs.SortedSets:
        return (
          <>
            <SortAscendingOutlined />
            {rawTitle}
          </>
        );
      default:
        return <>{rawTitle}</>;
    }
  }

  prepareTopMenu() {
    let logButton = undefined;
    if (this.state.cosInstance) {
      logButton = (
        <Button
          icon={<LinkOutlined />}
          type={"default"}
          onClick={() => {
            window.open(
              GlobalHelpers.prepareLogsLayoutLocation(this.projectId, {
                from: Math.floor(Date.now() / 1000) - 60 * 5,
                to: Math.floor(Date.now() / 1000) + 60,
                filters: {
                  classId: {
                    operator: "EQ",
                    value: this.classId,
                  },
                  instanceId: {
                    operator: "EQ",
                    value: this.state.cosInstance!.instanceId,
                  },
                },
              }),
              "_blank"
            );
          }}
        >
          Instance Logs
        </Button>
      );
    }
    topMenuStore.dispatch({
      type: ActionTypes.TOP_MENU_CHANGED.types.CHANGED,
      data: {
        extraMenu: [logButton],
      },
    });
  }

  render() {
    return (
      <>
        <Spin spinning={this.state.loading}>
          <>
            <Layout key={"cos_debug"}>
              <Sider
                collapsed={this.state.inlineCollapsed}
                key={"cos_debug_sider"}
                width={250}
                style={{
                  borderRadius: 12,
                  backgroundColor: this.props.token.colorBgContainer,
                  overflow: "hidden",
                }}
              >
                <Menu
                  style={{ overflow: "auto" }}
                  defaultOpenKeys={[]}
                  selectedKeys={[this.state.tabActiveKey]}
                  mode="inline"
                  onClick={(e) => {
                    this.treeCustomItemDoubleClick(e.key);
                  }}
                  inlineCollapsed={this.state.inlineCollapsed}
                >
                  <Menu.Item
                    key={"collapse_menu"}
                    icon={React.createElement(
                      this.state.inlineCollapsed
                        ? MenuUnfoldOutlined
                        : MenuFoldOutlined
                    )}
                    onClick={() => {
                      localStorage.setItem(
                        "RBS_LEFT_MENU_COLLAPSED",
                        this.state.inlineCollapsed ? "true" : "false"
                      );
                      this.setState({
                        inlineCollapsed: !this.state.inlineCollapsed,
                      });
                    }}
                  ></Menu.Item>
                  {this.state.treeData.map((d) => {
                    const isDisabled = d.children
                      ? d.children.length === 0
                      : !this.state.cosInstance;
                    return d.children ? (
                      <>
                        <Menu.SubMenu
                          icon={<ThunderboltOutlined />}
                          disabled={isDisabled}
                          key={d.key}
                          title={
                            <>
                              {d.title?.toString().includes("Static")
                                ? "Static Methods"
                                : "Methods"}{" "}
                              (<b>{d.children ? d.children.length : 0}</b>)
                            </>
                          }
                        >
                          {_.sortBy(
                            d.children.map((c) => {
                              return {
                                ...c,
                                sortKey: (c.title || "")
                                  .toString()
                                  .toLowerCase(),
                              };
                            }),
                            "sortKey"
                          ).map((c) => {
                            const type = (c as any).type
                            return (
                              <Menu.Item
                                title={c.title}
                                disabled={isDisabled}
                                key={c.key}
                                style= {{ paddingLeft: 10 }}
                              >
                                <Tag color={operationTypeColors[type]}>{type}</Tag>
                                {c.title}
                              </Menu.Item>
                            );
                          })}
                        </Menu.SubMenu>
                      </>
                    ) : (
                      <>
                        <Menu.Item
                          disabled={isDisabled}
                          icon={d.icon}
                          key={d.key}
                        >
                          {d.title}
                        </Menu.Item>
                      </>
                    );
                  })}
                </Menu>
              </Sider>
              <Layout
                key={"cos_debug_content_layout"}
                style={{ paddingLeft: 20 }}
              >
                <Tabs
                  hideAdd
                  onChange={this.TABonChange}
                  activeKey={this.state.tabActiveKey}
                  type="editable-card"
                  onEdit={async (e, a) => {
                    await this.TABonEdit(e, a);
                  }}
                  tabBarStyle={{ margin: 0 }}
                  className="no-border__before"
                >
                  {this.state.panes.map((pane) => (
                    <Tabs.TabPane
                      closable={pane.closable === undefined || !!pane.closable}
                      tab={this.TABgetTitle(pane.key)}
                      key={pane.key}
                      style={{
                        padding: 15,
                        backgroundColor: this.props.token.colorBgContainer,
                        borderRadius: 12,
                        borderTopLeftRadius: 0,
                        borderLeft: "1px solid " + this.props.token.colorSplit,
                        borderRight: "1px solid " + this.props.token.colorSplit,
                        borderBottom:
                          "1px solid " + this.props.token.colorSplit,
                      }}
                    >
                      {pane.content}
                    </Tabs.TabPane>
                  ))}
                </Tabs>
              </Layout>
            </Layout>
          </>
        </Spin>
      </>
    );
  }
}

CosDebugLayout.contextType = ThisProjectContext

export default withAntdToken(CosDebugLayout)
